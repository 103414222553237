import React, { useEffect } from "react";
import qs from "querystring";

// OAuth2 설정
const oAuth2Config = {
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    clientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    authEndpoint: process.env.REACT_APP_OAUTH_CODE_ENDPOINT,
    tokenEndpoint: process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT,
    redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
    scope: 'permissions',
};

const Login = () => {
    useEffect(() => {
        const response_type = "code";
        const authEndpoint = oAuth2Config.authEndpoint;
        const client_id = oAuth2Config.clientId;
        const redirect_uri = oAuth2Config.redirectUri;
        const scope = oAuth2Config.scope;

        const authUri = `${authEndpoint}?${qs.stringify({
            client_id,
            redirect_uri,
            response_type,
            scope
        })}`;

        window.location.href = authUri;

    },[]);

    return <div>loading...</div>
}

export default Login;

