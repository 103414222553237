import axios from 'axios';
import base64 from 'base-64';
import { updateToken } from './UpdateToken';

export async function validateToken(token) {
    if (token === null || token === undefined) {
        return false;
    }

    try {
        let result = await axios.get(process.env.REACT_APP_OAUTH_MEMBER_DETAIL_URI, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    } catch (error) {
        const refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken === null || refreshToken === undefined) {
            return false;
        }
        token = await updateToken(refreshToken);
        if (token === null) {
            return false;
        }
    }

    let payload = token.substring(token.indexOf('.')+1,token.lastIndexOf('.'));
    let decodedPayload = base64.decode(payload);
    const parsedPayload = JSON.parse(decodedPayload, null, 2)
    return parsedPayload.permissions.includes("P001");
}