import { HashRouter, Routes, Route } from 'react-router-dom';

import { IsLoginProvider } from './auth/IsLoginContext'
import Main from 'pages/Main';
import Login from 'pages/Login';
import Unauthorized from 'pages/Unauthorized';
import Callback from 'pages/Callback';

function App() {
  return (
    <HashRouter>
      <IsLoginProvider>
        <Routes>
          <Route path={'/'} element={<Main />} />
          <Route path={'/login'} element={<Login />} />
          <Route path={'/callback'} element={<Callback />} />
          <Route path={'/unauthorized'} element={<Unauthorized />} />
        </Routes>
      </IsLoginProvider>
    </HashRouter>
  )
}

export default App;
