import React from 'react';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f3f4f6',
      fontFamily: 'Arial, sans-serif'
    },
    card: {
      maxWidth: '400px',
      width: '100%',
      padding: '24px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    alert: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      marginBottom: '16px',
      color: '#991b1b',
      backgroundColor: '#fee2e2',
      borderTop: '4px solid #f87171',
      borderRadius: '4px'
    },
    alertIcon: {
      width: '24px',
      height: '24px',
      marginRight: '12px'
    },
    alertTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '4px'
    },
    alertDescription: {
      fontSize: '14px'
    },
    message: {
      marginTop: '16px',
      color: '#4b5563'
    },
    button: {
      display: 'block',
      width: '100%',
      marginTop: '24px',
      padding: '10px',
      backgroundColor: '#3b82f6',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      fontSize: '16px',
      fontWeight: 'bold',
      cursor: 'pointer'
    }
  };

  const handleGoToMainPage = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    navigate("/login"); // 메인 페이지의 경로를 '/'로 가정
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.alert}>
          <svg style={styles.alertIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
          <div>
            <div style={styles.alertTitle}>접근 권한 없음</div>
            <div style={styles.alertDescription}>죄송합니다. 이 페이지에 접근할 권한이 없습니다.</div>
          </div>
        </div>
        <p style={styles.message}>
          필요한 권한이 없어 이 페이지를 볼 수 없습니다. 권한 획득에 대해 관리자에게 문의하세요.
        </p>
        <button
          style={styles.button}
          onClick={handleGoToMainPage}
        >
          로그인 페이지로 돌아가기
        </button>
      </div>
    </div>
  );
};

export default UnauthorizedPage;