import axios from "axios";
import React, { useEffect, useContext, useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from "querystring";
import { IsLoginContext } from 'auth/IsLoginContext'

const authContext = createContext();
const useAuth = () => {
    return useContext(authContext);
}

const Callback = () => {
    // auth server로부터 code를 받아온 이후의 처리 진행
    const navigate = useNavigate();
    // const {setIsSignedIn, setProfile} = useAuth();
    const { setIsLogin } = useContext(IsLoginContext)
    const [error, setError] = useState(null);

    useEffect( () => {
        (async () => {
            const tokenEndpoint = process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT;
            const redirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI;
            const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;
            const authString = btoa(`${clientId}:${clientSecret}`)

            const params = qs.parse(document.URL.split('?')[1])
            const code = params.code;
            if (code) {
                try {
                    const params = new URLSearchParams();
                    params.append('grant_type', "authorization_code");
                    params.append('code', code);
                    params.append('redirect_uri', redirectUri)
                    const resp = await axios.post(tokenEndpoint, params,
                    {
                        headers: {
                            Authorization: `Basic ${authString}`
                        },
                    });

                    console.log({resp})

                    const {access_token, refresh_token} = resp.data;
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem("refresh_token", refresh_token);

                    // 사용자 정보 요청
                    // const profileResp = await axios.get(
                    //     "https://kr.dev.api.acs-auth.share.torder.com/member/detail",
                    //     {
                    //         headers: {
                    //             authorization: `Bearer ${access_token}`
                    //         },
                    //     });
                    // console.log({profileResp});
                    // const profile = {"email": profileResp.data.email};

                    // localStorage.setItem("profile", profile);
                    // setProfile(profile);

                    console.log({localStorage})
                    setIsLogin(true);
                    navigate("/");
                } catch (err) {
                    setError("server error")
                }
            }
        })();
    }, [navigate])

    if (error) {
        return <div>{error}</div>;
    }

    return <div>Loading...</div>;
}

export default Callback;