import { useCallback, useState, useEffect } from 'react';
import styles from './Main.module.css';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import Upload from 'images/upload.svg';

import { IsLoginContext, useIsLoginState } from 'auth/IsLoginContext'
import { validateToken } from 'auth/ValidateToken';
import { useNavigate } from 'react-router-dom';

const Main = () => {
  const navigate = useNavigate();

  const [fileInfo, setFileInfo] = useState(); // 단일 파일

  const LIMIT = 100; // 한 번 API 요청 가능한 데이터 개수
  const MAX_LIMIT = 5000; // 최대 데이터 개수 (엑셀)

  // 컬럼 값
  const HEADER_JSON = {
    b_no : "사업자등록번호",
    b_stt	 : "납세자상태(명칭)",
    b_stt_cd : "납세자상태(코드)",
    tax_type : "과세유형메세지(명칭)",
    tax_type_cd : "사업자등록번호(코드)",
    end_dt : "폐업일",
    utcc_yn : "단위과세전환폐업여부",
    tax_type_change_dt : "최근과세유형전환일자",
    invoice_apply_dt : "세금계산서적용일자",
    rbf_tax_type : "직전과세유형메세지(명칭)",
    rbf_tax_type_cd : "직전과세유형메세지(코드)"
  }

  // 상태값 코드
  const STATUS_CODE_JSON = [
    { codeType: "납세자상태", code: "01", desc: "계속사업자" },
    { codeType: "납세자상태", code: "02", desc: "휴업자" },
    { codeType: "납세자상태", code: "03", desc: "폐업자" },
    { codeType: "", code: "", desc: "" },
    { codeType: "과세유형메세지", code: "01", desc: "부가가치세 일반과세자" },
    { codeType: "과세유형메세지", code: "02", desc: "부가가치세 간이과세자" },
    { codeType: "과세유형메세지", code: "03", desc: "부가가치세 과세특례자" },
    { codeType: "과세유형메세지", code: "04", desc: "부가가치세 면세사업자" },
    { codeType: "과세유형메세지", code: "05", desc: "수익사업을 영위하지 않는 비영리법인이거나 고유번호가 부여된 단체,국가기관 등" },
    { codeType: "과세유형메세지", code: "06", desc: "고유번호가 부여된 단체" },
    { codeType: "과세유형메세지", code: "07", desc: "부가가치세 간이과세자(세금계산서 발급사업자)" },
    { codeType: "과세유형메세지", code: "", desc: "* 등록되지 않았거나 삭제된 경우: '국세청에 등록되지 않은 사업자등록번호입니다'" },
    { codeType: "", code: "", desc: "" },
    { codeType: "직전과세유형메세지", code: "01", desc: "부가가치세 일반과세자" },
    { codeType: "직전과세유형메세지", code: "02", desc: "부가가치세 간이과세자" },
    { codeType: "직전과세유형메세지", code: "07", desc: "부가가치세 간이과세자(세금계산서 발급사업자)" },
    { codeType: "직전과세유형메세지", code: "99", desc: "해당없음" },
  ];

  // 엑셀파일 업로드와 API 조회 후 결과 값 다운로드 하는 메서드
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const strFileInfo = `${file.path} (${(file.size / 1024).toFixed(2)} KB)`;
    setFileInfo(strFileInfo);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, {header: 1, defval: ''});

      const dataRows = data.slice(1).map(row => String(row[5])); // 사업자등록번호
      const result = await allData(dataRows.map(row => row.slice(0, 12).replace(/-/g,'')));

      if (result) {
        const today = new Date().toISOString().split('T')[0];
        const originalData = data.slice(1).map(row => [today, ...row]); // 전체 데이터 첫 열에 검증일자 추가

        await createExcelFile(originalData, result);
      }

      setFileInfo([]);
    };
    reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : ['.xlsx'],
    }, // MIME TYPE : [extensions] ex)  'image/*': ['.jpeg', '.png'] 'text/html': ['.html', '.htm'],
  });

  /**
   * 일정 단위(size)로 배열을 나누는 함수
   * @param {array} array
   * @param {number} size
   * @returns
   */
  const splitDataArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  /**
   * 컬럼의 키 값에 한글명 매핑
   * @param {*} originalData 업로드 데이터(원본 데이터)
   * @param {*} data API로 조회한 데이터
   * @returns
   */
  const convertColumns = (originalData, data) => {
    const originalColumns = ['검증일자', '회원번호', '회원명', '회사명', '대표자명/이름', '프랜차이즈명', '사업자등록번호' ];

    return data.map((item, index) => {
      const newItem = {};

      originalData[index].forEach((value, i) => {
        newItem[originalColumns[i]] = value;
      });

      for (const key in item) {
        if (HEADER_JSON.hasOwnProperty(key)) {
          newItem[HEADER_JSON[key]] = item[key];
        } else {
          newItem[key] = item[key]; // 매핑 객체에 없는 키는 그대로 사용
        }
      }

      return newItem;
    });
  }

  // 전체 데이터 처리 함수
  const allData = async (data) => {
    if (data.length > MAX_LIMIT) {
      alert('5000개까지 가능합니다.');
      return false;
    }

    const chunks = splitDataArray(data, LIMIT);

    let allResult = [];

    for(let i = 0; i < chunks.length; i ++) {
      const partialResponse = await fetchGetStatus(chunks[i]);
      allResult = [...allResult, ...partialResponse.data];
    }

    return allResult;
  }

  // 홈택스 사업자등록번호 상태조회 API
  const fetchGetStatus = async (data) => {
    try {
      const URL = `${process.env.REACT_APP_DATA_OPEN_API_BASE_URL}/nts-businessman/v1/status?serviceKey=${process.env.REACT_APP_DATA_OPEN_API_ENCODING_KEY}`;
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        },
        body: JSON.stringify({ b_no: data })
      });

      const result = await response.json();

      if (result.status_code !== 'OK') {
        alert(result.status_code);
        return;
      }

      return result;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  /**
   * 엑셀 다운로드
   * @param {*} originalData 업로드 데이터(원본 데이터)
   * @param {*} data API로 조회한 데이터
   */
  const createExcelFile = async (originalData, data) => {
    const workbook = XLSX.utils.book_new();
    const firstSheet = XLSX.utils.json_to_sheet(convertColumns(originalData, data)); // API 조회 결과 시트
    const secondSheet = XLSX.utils.json_to_sheet(STATUS_CODE_JSON); // 각 코드 값 설명 시트

    XLSX.utils.book_append_sheet(workbook, firstSheet, '상태조회 결과값');
    XLSX.utils.book_append_sheet(workbook, secondSheet, '코드값');

    const today = new Date().toISOString().split('T')[0];
    XLSX.writeFile(workbook, `${today}_사업자등록번호 상태조회.xlsx`);
  };

  // 템플릿 다운로드
  const downloadTemplate = () => {
    const url = '/upload-template.xlsx';
    const a = document.createElement('a');

    a.href = url;
    a.download = 'upload-template.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    (async () => {
      if (process.env.REACT_APP_USE_AUTH === 'Y') {
        const accessToken = localStorage.getItem('access_token');
        const tvresult = await validateToken(accessToken);
        if (!tvresult) {
          navigate("/unauthorized")
        }
      }
    })()
  });

  return (
    <div className={styles['container']}>
      <div className={styles['wrap']}>
        <div className={styles['title']}>사업자등록번호 검증 조회</div>
        <div className={styles['attachment-wrap']}>
          <div className={styles['item-wrap']}>
            <label>템플릿 다운로드 링크</label>
            <div className={styles['link']}>
              <p onClick={() => downloadTemplate()}>upload-template.xlsx</p>
            </div>
          </div>
          <div className={styles['item-wrap']}>
            <label>주의사항</label>
            <div className={styles['item-info']}>
              <p>1. 템플릿 다운로드 후 해당 템플릿에 작성 및 업로드해야합니다. (변경 X)</p>
              <p>2. 사업자등록번호는 <b style={{color: 'blue'}}>필수</b> 입니다. (10자리 or '-' 포함 12자리)</p>
              <p>3. 입력한 데이터가 많을 수록 시간이 오래 소요됩니다.</p>
              <p>4. 안되실 경우 화면 새로고침 후 시도 부탁드립니다.</p>
              <p>5. 1회당 5,000건 / 1일 1억건 조회 가능합니다.</p>
            </div>
          </div>
          <div
            className={styles['attachment']}
            {...getRootProps()}
          >
            <div className={styles['img']}><img src={Upload} alt='upload' /></div>
            <p>첨부파일을 드래그 앤 드롭하거나 클릭하여 업로드 하세요.</p>
            <p>1개의 파일만 업로드 가능합니다.</p>
          </div>
          <div className={styles['file-name-wrap']}>
            <input {...getInputProps()} />
            {fileInfo}
            {/* {files}
            {files2} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main;