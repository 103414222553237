import axios from 'axios';
import base64 from 'base-64';

export async function updateToken(refreshToken) {
    try {
        const tokenEndpoint = process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT;
        const redirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI;
        const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;
        const authString = btoa(`${clientId}:${clientSecret}`)

        const params = new URLSearchParams();
        params.append('grant_type', "refresh_token");
        params.append('refresh_token', refreshToken);
        params.append('redirect_uri', redirectUri);
        let response = await axios.post(tokenEndpoint, params,
        {
            headers: {
                Authorization: `Basic ${authString}`
            },
        });
        const {access_token, refresh_token} = response.data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        return access_token;
    } catch (error) {
        return null;
    }
}